import { lazy } from 'react';

import { RestPageConfig } from '@intelliarts/ia-react-template';

import { CATEGORY_SETTINGS_PAGE_LOCALE_PATH } from '../locales';
import authConfig, { NO_NAME_ADMIN_MENU_GROUP } from '../../../config/authConfig';
import { CATEGORIES_PATH, CategoryStore } from '../store/categoriesStore';
import { CategoryView } from '../models/CategoryView';
import { categoryDao } from './CategoryDao';
import { categoryFormDialogConfig } from './categoryFormDialogConfig';
import CategoriesImageSvg from '../../../images/menuIcons/categories-figma.svg';
import { PageIconWithTooltip } from '../../common/components/Icons/PageIconWithTooltip';

export const CategoriesPageConfig: RestPageConfig<CategoryView> = {
  name: `${CATEGORY_SETTINGS_PAGE_LOCALE_PATH}.name`,
  path: CATEGORIES_PATH,
  allowedRoles: [ authConfig.roles.ADMIN ],
  dao: categoryDao,
  formConfig: categoryFormDialogConfig,
  List: lazy(() => import('../components/CategoryList')),
  Details: lazy(() => import('../../../components/pages/NotFoundPage')),
  storeDependencies: [
    { store: CategoryStore },
  ],
  menuItemConfig: {
    icon: () => PageIconWithTooltip(`${CATEGORY_SETTINGS_PAGE_LOCALE_PATH}.name`, 'categories', CategoriesImageSvg),
    label: `${CATEGORY_SETTINGS_PAGE_LOCALE_PATH}.label`,
    group: NO_NAME_ADMIN_MENU_GROUP
  }
};
