import { ReactPageConfig } from '@intelliarts/ia-react-template';
import { REPORTS_PAGE_LOCALE_PATH } from '../locales';
import { lazy } from 'react';
import authConfig, { NO_NAME_REPORTS_GROUP } from '../../../config/authConfig';
import { useStringDateRangeProps } from '../../../utils';
import { ReportsStore } from '../store/reportsStore';
import { UserNamesStore } from '../../users/store/userNamesStore';
import ReportsImageSvg from '../../../images/menuIcons/menu-report-figma.svg';
import { PageIconWithTooltip } from '../../common/components/Icons/PageIconWithTooltip';

import { UsersToFilterReportsByStore } from '../store/userNamesToFilterReportsByStore';
import { MenuStore } from '../../Menu/store/menuStore';
import { VendorReportStore } from '../store/vendorReportStore';

export const ReportsPageConfig: ReactPageConfig = {
  name: `${REPORTS_PAGE_LOCALE_PATH}.name`,
  path: '/report/menu',
  allowedRoles: [ authConfig.roles.ADMIN, authConfig.roles.USER ],
  component: lazy(() => import('../components/ReportsPage')),
  storeDependencies: [
    { store: MenuStore, useProps: useStringDateRangeProps },
    { store: ReportsStore, useProps: useStringDateRangeProps },
    { store: VendorReportStore, useProps: useStringDateRangeProps },
    { store: UsersToFilterReportsByStore },
    { store: UserNamesStore },
  ],
  menuItemConfig: {
    icon: () => PageIconWithTooltip(`${REPORTS_PAGE_LOCALE_PATH}.name`, 'reports', ReportsImageSvg),
    label: `${REPORTS_PAGE_LOCALE_PATH}.label`,
    group: NO_NAME_REPORTS_GROUP,
  },
};