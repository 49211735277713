export default {
  sidebar: {
    label: {
      search: 'Search',
      names: 'Names',
      status: 'Status:',
      printReport: 'Print orders',
      dish: 'Dish',
      count: 'Count',
      openReportPanel: 'Open report panel',
      closeReportPanel: 'Close report panel',
    },
    value: {
      statusPublished: 'Published'
    }
  },
  ordersList: {
    noOrders: 'Nobody has ordered anything... yet',
    tile: {
      noMeal: 'Nothing was ordered'
    }
  }
};