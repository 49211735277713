import { createDataHook } from '@intelliarts/ia-react-template';
import { DishesStore } from './dishesStore';
import { DishView } from '../models/DishView';

const sortById = (a: DishView, b: DishView,) => {
  return a.id > b.id ? 1 : -1;
};

const getSortedDishesViews = (dishes: DishView[]) =>
  dishes
    .sort(sortById);

export const useDishes = createDataHook(DishesStore);

export const useSortedDishesViews = createDataHook(DishesStore, getSortedDishesViews);
