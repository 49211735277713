import { SnackbarWithSeverity } from './SnackbarWithSeverity';
import { useScreenRatioCheck } from '../../../../utils';
import React from 'react';
import { theme } from '@intelliarts/react-components';

const INFO_MSG = 'Your screen size seems to be unusual. Some UI issues might be seen (ノಠ益ಠ)ノ彡┻━┻';

export const ScreenOrientationMessage = ({}) => {
  const isUncommonRatio = useScreenRatioCheck();
  const styles = {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.contrastText}`
  };

  return (
    // msg is not translated since it's used before i18n setup, outside i18n provider in the ia-template.
    // Proper mobile view support will negate the need for this message whatsoever.
    <SnackbarWithSeverity open={isUncommonRatio}
                          message={INFO_MSG}
                          severity="warning"
                          autoHideDuration={null}
                          position={{ vertical: 'top', horizontal: 'center' }}
                          variant="filled"
                          alertSx={styles} />
  );
};
