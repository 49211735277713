import { lazy } from 'react';

import authConfig, { NO_NAME_ADMIN_MENU_GROUP } from '../../../config/authConfig';
import { CategoryStore } from '../../Categories/store/categoriesStore';
import { DishesStore } from '../../Dishes/store/dishesStore';
import { MenuStore } from '../store/menuStore';

import { ReactPageConfig } from '@intelliarts/ia-react-template';

import { MENU_SETTINGS_PAGE_LOCALE_PATH } from '../locales';
import { useStringDateRangeProps } from '../../../utils';
import MenuImageSvg from '../../../images/menuIcons/menu-figma.svg';
import { PageIconWithTooltip } from '../../common/components/Icons/PageIconWithTooltip';

import { MenuAccordionsStateStore } from '../store/menuAccordionsState';
import { OrderStore } from '../../Orders/store/orderStore';
import { SelectedCategoryIdStore } from '../../Dishes/store/selectedCategoryIdStore';

export const MenuPageConfig: ReactPageConfig = {
  name: `${MENU_SETTINGS_PAGE_LOCALE_PATH}.name`,
  path: '/menu',
  allowedRoles: [ authConfig.roles.ADMIN ],
  component: lazy(() => import('../components/MenuPage')),
  storeDependencies: [
    { store: MenuStore, useProps: useStringDateRangeProps },
    { store: OrderStore, useProps: useStringDateRangeProps },
    { store: MenuAccordionsStateStore },
    { store: CategoryStore },
    { store: DishesStore },
    { store: SelectedCategoryIdStore }
  ],
  menuItemConfig: {
    icon: () => PageIconWithTooltip(`${MENU_SETTINGS_PAGE_LOCALE_PATH}.name`, 'menu', MenuImageSvg),
    label: `${MENU_SETTINGS_PAGE_LOCALE_PATH}.label`,
    group: NO_NAME_ADMIN_MENU_GROUP,
  },
};