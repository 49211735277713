import { Tooltip } from '@intelliarts/react-components';
import React, { FunctionComponent, SVGAttributes, useState } from 'react';
import { SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const PageIconWithTooltip = (titlePath: string, name: string, icon: FunctionComponent<SVGAttributes<SVGElement>>) => {
  const { t } = useTranslation();
  const [ open, setOpen ] = useState(false);
  const closeTooltip = () => setOpen(false);
  const openTooltip = () => setOpen(true);

  return <Tooltip onClose={closeTooltip}
                  open={open}
                  title={t(titlePath)}>
    <Typography noWrap
                onClick={openTooltip}
                onMouseEnter={openTooltip}>
      <SvgIcon data-testid={`navigation-bar-icon-${name}`} component={icon} />
    </Typography>
  </Tooltip>;
};