import { GridRowClassNameParams } from '@mui/x-data-grid-pro';

import { DishView } from '../models/DishView';
import { CategoryView } from '../../Categories/models/CategoryView';

export const filterArchivedDishes = (food: DishView[]) => food.filter(dish => !dish.isArchived);

//TODO: chore to decide what prop to use for most uniKness
export const useRowClassName = ({ row }: GridRowClassNameParams) => {
  const { isArchived } = row;
  if (isArchived) {
    return `row-dish-archived-${row.name}`;
  } else {
    return `row-dish-${row.name}`;
  }
};

export const getDefaultBlockedCategories = (selectedCategory: CategoryView | undefined, selectedRow: DishView | null, categories: CategoryView[]) => {
  const mainDishName = 'Основна';
  const garnishName = 'Гарнір';
  const meatName = 'М\'ясо';
  const defaultBlockedCategories: CategoryView[] = [];
  if (!selectedRow && selectedCategory) {
    if (selectedCategory.name === mainDishName) {
      const garnish = categories.find(c => c.name === garnishName && !c.isArchived);
      const meat = categories.find(c => c.name === meatName && !c.isArchived);
      meat ? defaultBlockedCategories.push(meat) : null;
      garnish ? defaultBlockedCategories.push(garnish) : null;
      return defaultBlockedCategories;
    } else if (selectedCategory.name === garnishName || selectedCategory.name === meatName) {
      const main = categories.find(c => c.name === mainDishName && !c.isArchived);
      main ? defaultBlockedCategories.push(main) : null;
      return defaultBlockedCategories;
    }
  } else if (selectedRow && selectedRow.blockedCategories !== null && selectedRow.blockedCategories.length > 0) {
    return selectedRow.blockedCategories;
  }
  return [];
};
