import React from 'react';

import { useTranslation } from 'react-i18next';
import { CategoryIcon, categoryIcons } from '../CategoryIcon';
import { CATEGORIES_BASE_LOCALE_PATH } from '../../locales';
import { CategoryViewFields } from '../../models/CategoryView';
import { Select } from 'mui-rff';
import { Box, MenuItem } from '@mui/material';

const LOCALE_PATH = `${CATEGORIES_BASE_LOCALE_PATH}.categoryFormDialog.icon`;
export const CategoryIconField = () => {
  const { t } = useTranslation();

  return <Select
    variant="standard"
    sx={{ marginTop: '20px', width: '100%' }}
    label={t(`${LOCALE_PATH}.helperText`)}
    name={CategoryViewFields.ICON}>
    {categoryIcons.map(categoryIcon => (
      <MenuItem
        value={categoryIcon.name}
        key={categoryIcon.name}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CategoryIcon icon={categoryIcon.name} isWhite={false} />
          {'  '}{categoryIcon.name}
        </Box>
      </MenuItem>
    ))}
  </Select>;
};