import { useCategoriesData } from './categoriesStore';
import { CategoryView } from '../models/CategoryView';
import { MenuItemType } from '../../Menu/models/MenuItemType';

const sortBySequence = (a: CategoryView, b: CategoryView,) => {
  return a.position > b.position ? 1 : -1;
};

export const useNextAvailableSequence = () => {
  const STEP_BETWEEN_SEQUENCES = 1;
  const sequences = useCategoriesData().map(cat => cat.position);
  const currentMaxSequence = Math.max(...sequences);
  return currentMaxSequence + STEP_BETWEEN_SEQUENCES;
};

export const useCategories = () => {
  return useCategoriesData()
    .filter(c => !c.isArchived)
    .sort(sortBySequence);
};

export const useSortedCategoryViews = () => {
  return useCategoriesData()
    .sort(sortBySequence);
};

export const useIsCategoryItemSelected = (categoryId: number, orderItems: MenuItemType[]) => {
  return orderItems
    .flatMap(dailyOrder => dailyOrder.food.category)
    .filter(category => category.id === categoryId)
    .length > 0;
};
