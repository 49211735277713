import { useTranslation } from 'react-i18next';
import { useCommonDataGridProps } from '@intelliarts/react-components';

export const useLocalizedCommonDataGridProps = () => {
  const { i18n } = useTranslation();

  return useCommonDataGridProps({
    currentLanguage: i18n.language
  });
};

export const isDefined = (value: any): boolean => typeof value !== 'undefined';
