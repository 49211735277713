import React from 'react';

import { App, patchAxiosConfig } from '@intelliarts/ia-react-template';
import { theme } from '@intelliarts/react-components';

import config from './config';
import { ScreenOrientationMessage } from './components/common/components/Snackbars/ScreenOrientationSnackbar';

export const BASE_URL = '/api';

patchAxiosConfig({ baseURL: BASE_URL, timeout: 30000 }); // timeout 30000 = 30 seconds

export default function LuncherApp(): JSX.Element {
  return (
    <>
      <App theme={theme} config={config} />
      <ScreenOrientationMessage />
    </>
  );
}
