import { useEffect, useState } from 'react';

const PORTRAIT_RATIO_THRESHOLD = 2.5;
const LANDSCAPE_RATIO_THRESHOLD = 0.6;

export const checkRatio = () => {
  const height = window.screen.availHeight;
  const width = window.screen.availWidth;
  const aspectRatio = height / width;
  const orientationType = window.screen.orientation.type;

  if (orientationType.startsWith('portrait')) {
    return aspectRatio > PORTRAIT_RATIO_THRESHOLD;
  }

  if (orientationType.startsWith('landscape')) {

    if (height > width) { // iOS Safari specific
        return aspectRatio < PORTRAIT_RATIO_THRESHOLD;
    }

    return aspectRatio < LANDSCAPE_RATIO_THRESHOLD;
  }

  return false;
};

export const useScreenRatioCheck = () => {
  const [ isUncommonRatio, setIsUncommonRatio ] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsUncommonRatio(checkRatio());
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isUncommonRatio;
};
