export default {
  sidebar: {
    label: {
      search: 'Пошук',
      names: 'Імена',
      status: 'Статус:',
      printReport: 'Друк замовлень',
      dish: 'Страва',
      count: 'Кількість',
      openReportPanel: 'Відкрити панель звітів',
      closeReportPanel: 'Закрити панель звітів',
    },
    value: {
      statusPublished: 'Опубліковано'
    }
  },
  ordersList: {
    noOrders: 'Жодного замовлення не зроблено. Поки що...',
    tile: {
      noMeal: 'Нічого не замовлено'
    }
  }
};