import React from 'react';
import { SvgIcon } from '@mui/material';
import SoupPlateImageSvg from '../../../images/categories/soup-plate.svg';
import RiceBowlImageSvg from '../../../images/categories/rice-bowl.svg';
import CarbohydratesImageSvg from '../../../images/categories/carbohydrates.svg';
import KawaiiSteakImageSvg from '../../../images/categories/kawaii-steak.svg';
import KetchupImageSvg from '../../../images/categories/ketchup.svg';
import SaladImageSvg from '../../../images/categories/salad.svg';
import IceCreamConeImageSvg from '../../../images/categories/ice-cream-cone.svg';
import CabbageImageSvg from '../../../images/categories/cabbage.svg';
import HalalFoodImageSvg from '../../../images/categories/halal-food.svg';

export const categoryIcons = [
  {
    name: 'Soup',
    icon: SoupPlateImageSvg
  },
  {
    name: 'Main',
    icon: RiceBowlImageSvg
  },
  {
    name: 'Garnish',
    icon: CarbohydratesImageSvg
  },
  {
    name: 'Meat',
    icon: KawaiiSteakImageSvg
  },
  {
    name: 'Sauce',
    icon: KetchupImageSvg
  },
  {
    name: 'Salad',
    icon: SaladImageSvg
  },
  {
    name: 'Dessert',
    icon: IceCreamConeImageSvg
  },
  {
    name: 'Vegetables',
    icon: CabbageImageSvg
  },
  {
    name: 'General',
    icon: HalalFoodImageSvg
  },
];

export const CategoryIcon = ({ icon, isWhite }: { icon: string, isWhite: boolean }) => {
  const categoryIcon = categoryIcons.filter(i => i.name === icon || i.name === 'General')[0].icon;

  return (
    <SvgIcon
      component={categoryIcon}
      viewBox="0 0 344 344"
      sx={{
        marginLeft: '0.3em',
        marginRight: '0.3em',
        height: '0.93em',
        '& g': {
          fill: (theme) => (isWhite ? theme.palette.common.white : theme.palette.common.black),
        },
      }}
    />
  );
};