import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';
import { Alert, AlertColor, SxProps } from '@mui/material';
import { Theme } from '@intelliarts/react-components';
import { isDefined } from '../../../../utils';

const DEFAULT_HIDE_DURATION = 5000;

export interface SnackbarWithSeverityProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  variant?: 'filled' | 'outlined' | 'standard';
  position?: SnackbarOrigin;
  autoHideDuration?: number | null;
  snackBarSx?: SxProps<Theme>;
  alertSx?: SxProps<Theme>;
}

export const SnackbarWithSeverity = (props: SnackbarWithSeverityProps) => {
  const [ open, setOpen ] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [ props.open ]);

  const handleClose = () => setOpen(false);
  const autoHideDuration = isDefined(props.autoHideDuration) ? props.autoHideDuration : DEFAULT_HIDE_DURATION;

  return (
    <Snackbar open={open}
              autoHideDuration={autoHideDuration}
              anchorOrigin={props.position}
              sx={props.snackBarSx}
              onClose={handleClose}>
      <Alert severity={props.severity || 'info'}
             variant={props.variant || 'filled'}
             sx={props.alertSx}
             onClose={handleClose}>
        {props.message || ''}
      </Alert>
    </Snackbar>
  );
};
