import { lazy } from 'react';

import { ReactPageConfig } from '@intelliarts/ia-react-template';

import authConfig, { NO_NAME_USER_MENU_GROUP } from '../authConfig';
import SettingsImageSvg from '../../images/menuIcons/settings-figma.svg';
import { PageIconWithTooltip } from '../../components/common/components/Icons/PageIconWithTooltip';

export const SettingsPageConfig: ReactPageConfig = {
  name: 'src.config.routesConfigs.settingsPageConfig.name',
  path: 'settings',
  component: lazy(() => import('../../components/pages/Settings')),
  allowedRoles: authConfig.roles.ALL,
  storeDependencies: [],
  menuItemConfig: {
    icon: () => PageIconWithTooltip(`src.config.routesConfigs.settingsPageConfig.name`, 'settings', SettingsImageSvg),
    label: 'src.config.routesConfigs.settingsPageConfig.label',
    group: NO_NAME_USER_MENU_GROUP,
  }
};