import { createActionsHook, createContainer, StoreActionApi } from 'react-sweet-state';

import {
  createDataHook,
  createDeleteAction,
  createGetAction,
  createLoadingHook,
  createPostAction,
  createPutAction,
  createRestStore,
  getAxiosInstance,
  Mapper,
  RestStoreState,
  UrlOptions,
} from '@intelliarts/ia-react-template';

import { Menu } from '../models/Menu';
import { StringDateRangeProps } from '../../common/models/DateRangeProps';
import { DishView } from '../../Dishes/models/DishView';
import { MenuStatusType } from '../models/MenuStatusType';
import { MenuItemType } from '../models/MenuItemType';

export const MENUS_PATH = '/menus';

const BASE_URL = '/api';

export const MenuContainer = createContainer({
  displayName: 'menu-container'
});

const actionConfig = {
  path: MENUS_PATH,
  axiosConfig: { baseURL: BASE_URL },
};

const actions = {
  createMenu: (menu: Menu) => ({ dispatch }: StoreActionApi<RestStoreState<Menu>>) => {
    const customMapper: Mapper<any, any> = {
      toEntity: model => {
        return { firstDate: model.firstDate, lastDate: model.lastDate, status: model.status };
      },
      toModel: entity => entity,
    };

    const postAction = createPostAction<Menu>(
      actionConfig,
      customMapper
    );
    return dispatch(postAction(menu));
  },

  deleteMenu: (menu: Menu) => ({ dispatch }: StoreActionApi<RestStoreState<Menu>>) => {
    const deleteAction = createDeleteAction<Menu>({
      axiosConfig: { baseURL: BASE_URL },
      path: `/menus`,
      successAction: () => (storeActionApi) => {
        const state = storeActionApi.getState();
        const result = state.data.filter(m => m.id !== menu.id);
        storeActionApi.setState({ ...state, data: result });
      }
    });

    return dispatch(deleteAction(menu));
  },

  updateMenuStatus: (menu: Menu, options: UrlOptions, status: MenuStatusType) => ({ dispatch }: StoreActionApi<RestStoreState<Menu>>) => {
    const customMapper: Mapper<any, any> = {
      toEntity: model => status.toString(),
      toModel: entity => entity,
    };

    const putAction = createPutAction<Menu>(
      {
        axiosConfig: {
          baseURL: BASE_URL,
          headers: { 'Content-Type': 'text/plain' }
        },
        path: `/menus/${options.params?.path}`,
        successAction: () => (storeActionApi) => {
          const state = storeActionApi.getState();
          const result = state.data.map(m => {
            if (m.id === menu.id) {
              return {
                ...m,
                status: status
              };
            }
            return m;
          });
          storeActionApi.setState({ ...state, data: result });
        }
      },
      customMapper
    );
    return dispatch(putAction(menu));
  },

  createDailyMenu: (menu: Menu, options: UrlOptions, date: string) => async ({ dispatch }: StoreActionApi<RestStoreState<Menu>>) => {
    const customMapper: Mapper<any, any> = {
      toEntity: model => {
      },
      toModel: entity => entity,
    };

    const getAction = createGetAction<Menu>({
      axiosConfig: { baseURL: BASE_URL },
      path: `/menus?firstDate=${menu.firstDate}&lastDate=${menu.lastDate}`,
      successAction: (result) => (storeActionApi) => {
        const state = storeActionApi.getState();
        storeActionApi.setState({ ...state, data: Array.isArray(result) ? result : [ result ] });
      },
    });

    const putAction = createPutAction<Menu>(
      {
        axiosConfig: { baseURL: BASE_URL },
        path: `/menus/${options.params?.path}`,
        successAction: () => (storeActionApi) => {
          const state = storeActionApi.getState();
          const result = state.data.map(m => {
            if (m.id === menu.id) {
              return {
                ...m,
                lastDate: date
              };
            }
            return m;
          });
          storeActionApi.setState({ ...state, data: result });
        }
      },
      customMapper
    );

    await dispatch(putAction(menu));

    return await dispatch(getAction());
  },

  createFirstDayOfTheDailyMenu: (menu: Menu, options: UrlOptions, date: string) => async ({ dispatch }: StoreActionApi<RestStoreState<Menu>>) => {
    const customMapper: Mapper<any, any> = {
      toEntity: model => {
      },
      toModel: entity => entity,
    };

    const getAction = createGetAction<Menu>({
      axiosConfig: { baseURL: BASE_URL },
      path: `/menus?firstDate=${menu.firstDate}&lastDate=${menu.lastDate}`,
      successAction: (result) => (storeActionApi) => {
        const state = storeActionApi.getState();
        storeActionApi.setState({ ...state, data: Array.isArray(result) ? result : [ result ] });
      },
    });

    const putAction = createPutAction<Menu>(
      {
        axiosConfig: { baseURL: BASE_URL },
        path: `/menus/${options.params?.path}`,
        successAction: () => (storeActionApi) => {
          const state = storeActionApi.getState();
          const result = state.data.map(m => {
            if (m.id === menu.id) {
              return {
                ...m,
                firstDate: date
              };
            }
            return m;
          });
          storeActionApi.setState({ ...state, data: result });
        }
      },
      customMapper
    );

    await dispatch(putAction(menu));

    return await dispatch(getAction());
  },

  deleteDailyMenu: (menu: Menu, options: UrlOptions, first_date: string, last_date: string) => async ({ dispatch }: StoreActionApi<RestStoreState<Menu>>) => {
    const customMapper: Mapper<any, any> = {
      toEntity: model => {
      },
      toModel: entity => entity,
    };

    const deleteAction = createDeleteAction<Menu>({
        axiosConfig: { baseURL: BASE_URL },
        path: `/menus/${options.params?.path}`,
      },
      customMapper);

    const getAction = createGetAction<Menu>({
      axiosConfig: { baseURL: BASE_URL },
      path: `/menus?firstDate=${first_date}&lastDate=${last_date}`,
      successAction: (result) => (storeActionApi) => {
        const state = storeActionApi.getState();
        storeActionApi.setState({ ...state, data: Array.isArray(result) ? result : [ result ] });
      },
    });
    await dispatch(deleteAction(menu));

    return await dispatch(getAction());
  },

  removeMenuItem: (menu: Menu, options: UrlOptions) => async ({
                                                                getState,
                                                                setState
                                                              }: StoreActionApi<RestStoreState<Menu>>) => {
    const axiosInstance = getAxiosInstance({ baseURL: BASE_URL });
    const otherMenus: Menu[] = getState().data.filter(m => m.id !== menu.id);
    try {
      const response = await axiosInstance.delete<unknown, Menu>(`/menus/${options.params?.path}/${options.params?.resourceId}`, {});
      setState({
        data: [ ...otherMenus, {
          ...menu,
          items: menu.items.filter(item => item.id !== options.params?.resourceId)
        } ]
      });
      return response;
    } finally {
    }
  },

  addMenuItem: (menu: Menu, options: UrlOptions, item: { date: string, food: DishView }) =>
    async ({ getState, setState }: StoreActionApi<RestStoreState<Menu>>) => {
      const axiosInstance = getAxiosInstance({ baseURL: BASE_URL });
      const otherMenus: Menu[] = getState().data.filter(m => m.id !== menu.id);
      try {
        const response = await axiosInstance.post<unknown, MenuItemType>(`/menus/${options.params?.path}`, item, {});
        setState({ data: [ ...otherMenus, { ...menu, items: [ ...menu.items, response ] } ] });
        return response;
      } finally {
      }
    }
};

export const MenuStore = createRestStore<Menu, Menu, StringDateRangeProps>({
  actions,
  containedBy: MenuContainer,
  actionConfig,
  usePropsAsActionParams: true,
});

export const useMenuActions = createActionsHook(MenuStore);

export const useMenuIsLoading = createLoadingHook(MenuStore);

export const useMenu = createDataHook(MenuStore);